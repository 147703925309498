import React, { Component } from "react";
import styled from "styled-components";

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 10%;
  /* height: 315px;  */
  overflow: hidden;
  max-width: 1100px;
  margin: 0 auto;
  aspect-ratio: 16/9;
`;

const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

class ResponsiveFrame extends Component {
  state = {};
  render() {
    return (
      <EmbedContainer>
        <Frame
          src={this.props.src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
          {" "}
        </Frame>
      </EmbedContainer>
    );
  }
}

export default ResponsiveFrame;
