import React from "react";
import styled from "styled-components";
import { colors } from "../colors/colors";

const NavButton = styled.div`
  padding: 15px 0 20px 0;
  padding: ${(props) => props.margin && "5px 0"};
  text-align: center;
  z-index: 2;
  margin: ${(props) => props.margin && "0 10px"};
`;
const TheButton = styled.button`
  color: white;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-weight: 600;
  text-transform: uppercase;
  background: ${colors.red};
  padding: 10px 25px;
  margin: 0 0 30px 0;
  margin: ${(props) => props.margin && "0"};

  font-size: 16px;
  cursor: pointer;
  transition: 0.81s ease;
  outline: none;

  :hover {
    color: white;
    filter: brightness(1.2);
    border: 1px solid $main;
  }

  :focus {
    outline: none;
  }
`;

const Button = (props) => (
  <NavButton margin={props.margin}>
    <TheButton margin={props.margin}>{props.title}</TheButton>
  </NavButton>
);

export default Button;
