import React from "react";
import styled from "styled-components";
import { colors } from "../colors/colors";

import logo from "../images/logo.png";

const SectionIntroContainer = styled.div`
  text-align: center;
  max-width: 900px;
  padding: 0 50px;
  margin: 0 auto;
  color: ${colors.copy};

  @media (max-width: 600px) {
    padding: 0 20px;
  }

  ::after {
    background-color: ${colors.red};
    content: "";
    width: 150px;
    height: 3px;
    display: block;
    margin: 35px auto;
  }
`;
const SectionImage = styled.img`
  width: 60px;
  margin: 30px;
`;
const SectionTitle = styled.h1`
  font-family: "Tanker";
  font-size: 56px;
  font-weight: 400;
  word-break: break-word;
  margin: 0;
  color: ${colors.copy};
  background-color: ${colors.copy};
  background-image: linear-gradient(45deg, #fb045d, #ff6c02);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  @media (max-width: 600px) {
    font-size: 35px;
    margin-bottom: 20px;
  }
`;
const SectionCopy = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 200%;
  margin: 0;
  color: ${colors.copy};
`;

const SectionIntro = (props) => (
  <SectionIntroContainer>
    <SectionImage src={logo} alt="logo" />
    <SectionTitle>{props.title}</SectionTitle>
    <SectionCopy>{props.copy}</SectionCopy>
  </SectionIntroContainer>
);

export default SectionIntro;
