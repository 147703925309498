import React, { Component } from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import SectionIntro from "../components/section-intro";
import ResponsiveFrame from "../components/responsive-frame";
import { Link } from "gatsby";
import Button from "../components/button";
import SEO from "../components/seo";
import { colors } from "../colors/colors";

const Copy = styled.p`
  font-family: "Poppins";
  text-align: center;
  padding: 20px 50px;
  line-height: 200%;
  max-width: 900px;
  margin: 0 auto;
  font-size: 15px;
  opacity: 0.9;
  color: ${colors.copy};
`;

class YupCaresPage extends Component {
  state = {};
  render() {
    return (
      <Layout>
        <SEO
          title="YUP Cares"
          keywords={[
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />

        <SectionIntro
          title="YupCares"
          copy="We provide service to the less fortunate through our fellowship of businesses and industry professionals."
        />

        <ResponsiveFrame src="https://www.youtube.com/embed/UpmA-p_PGxs" />
        <Copy>
          YupCares consists of young leaders, friends, and problem-solvers who
          are driven to unite and create lasting change in our communities, and
          in young people. Selfless help requires a high level of commitment and
          vision.
          <br />
          <br />
          YupCares is still in its infancy stage, however it’s members have used
          their passion, energy, and intelligence to take action in charitable
          projects such as YupCares Annual Christmas Treat, assisting in the
          National Special Olympics, Fun Day for disabled kids. YupCares is
          always seeking to better our nation, and we stay dedicated to the end.
        </Copy>

        <Link to="/join">
          <Button title="Join the Team" />
        </Link>
      </Layout>
    );
  }
}

export default YupCaresPage;
